<template>
    <div class="contact">
        <div class="page-top">
            <div class="logo" @click="goto('/')">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/logo.png)"></div>
                </div>
            </div>
        </div>
        <div class="mainbox">
            <div class="main-item">
                <div class="qrcode">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/qrcode_weixin.png)"></div>
                    </div>
                    <div class="txt">扫码关注微信公众号</div>
                </div>
                <a class="itemtitle">
                    <div class="title">
                        <div class="atitle">上海市普陀区精神卫生中心</div>
                        <div class="btitle">上海市普陀区精神卫生中心是一家集医、教、研、防、康复于一体的二级专科医院，医院为区级精神、心理疾病的防治中心。</div>
                        <div class="txt">
                            <div class="t1">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/screensaver_phone.png)"></div>
                                </div>
                                <span>021-962525（市级心理热线）</span>
                            </div>
                            <div class="t2" @click="openMap(base.mapLink)">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/screensaver_location.png)"></div>
                                </div>
                                <span>上海市普陀区志丹路211号</span>
                            </div>
                        </div>
                    </div>
                    <button class="btn" @click="openMap(base.webUrl)"><span>查看区域地图</span></button>
                </a>
            </div>
            <div class="itemlist">
                <div class="list-title">社区网点</div>
                <div class="item" v-for="(item, index) in list" :key="index">
                    <a class="itemtitle">
                        <div class="atitle">{{item.title}}</div>
                        <div class="btitle">{{item.zhaiyao}}</div>
                        <div class="txt">
                            <a class="t2" :class="{show: item.fields.address}" @click="openMap(item.linkUrl)">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/screensaver_location.png)"></div>
                                </div>
                                <span>{{item.fields.address}}</span>
                            </a>
                            <div class="t1" :class="{show: item.fields.phone}">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/screensaver_phone.png)"></div>
                                </div>
                                <span>{{item.fields.phone}}</span>
                            </div>
                        </div>
                    </a>
                    <div class="more">
                        <button class="btn" @click="detailOn(item.id, `${item.title}团队介绍`)">查看详情</button>
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/more.png)"></div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <div class="popup" :class="{active: detailShow}">
            <div class="shadow" @click="detailOff"></div>
            <div class="detail-popup">
                <div class="titlebox">
                    <div class="atitle">{{popupTitle}}</div>
                    <div class="close" @click="detailOff">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/close.png)"></div>
                        </div>
                    </div>
                </div>
                <div class="conbox" v-html="string" style="white-space:pre-wrap;"></div>
            </div>
        </div>
        <el-dialog
            title="导航"
            v-model="dialogVisible"
            width="80%"
            height="100%"
            :before-close="handleClose"
            >
            <iframe id="reportFrame" frameborder="0" width="100%" height="600px" scrolling="no"></iframe>
        </el-dialog>
    </div>
</template>

<script>
import { getComNetList } from "@/js/request";
import { getBase } from "@/js/request";
import { getInfo } from "@/js/request";
export default {
    name: 'Contact',
    components: {
    },
    data() {
        return {
            base: {},
            list: [],
            detailShow: false,
            string: '',
            popupTitle: '',
            dialogVisible: true,
        }
    },
    methods: {
        goto(adress, cate) {
            this.$router.push({path: adress, query: cate});
        },
        detailOff() {
            this.detailShow = false;
        },
        detailOn(id, title) {
            this.detailShow = true;
            this.popupTitle = title;
            getInfo(id).then(res => {
                this.string = res.data.content;
            });
        },
        openMap(mapUrl) {
            this.dialogVisible = true;
            document.getElementById("reportFrame").src = mapUrl;
        }
    },
    mounted() {
        this.dialogVisible = false;
        this.$root.showRight();
        getBase().then(res => {
            this.base = res.data;
        });
        getComNetList().then(res => {
            this.list = res.data;
            
            // res.data.forEach((val, key) => {
            //     list[key].atitle = val.title;
            //     list[key].btitle = val.zhaiyao;
            //     list[key].location = val.fields.address;
            // })
        });
    }
}
</script>

<style lang="scss">
.contact {
    width: 1400px;
    margin: 0 auto;
    padding: 60px 0;
    .page-top {
        @include sys-flex(space-between, row);
        .logo {
            @include sys-picbox(438px, 80px);
            cursor: pointer;
        }
    }
    .mainbox {
        width: 100%;
        margin-top: 100px;
        .main-item {
            width: 100%;
            height: 380px;
            margin-bottom: 100px;
            display: flex;
            .qrcode {
                @include sys-flex();
                @include sys-boxsize(340px, 100%, 10px, #fff);
                flex: none;
                margin-right: 100px;
                width: 340px;
                height: 100%;
                padding: 10px;
                box-sizing: border-box;
                box-shadow: 0px 0px 50px 0px rgba(63,101,224,0.05);
                @include sys-picbox(320px, 320px, contain, 103%, 103%);
                .txt {
                    @include sys-text-hide(1);
                    @include sys-font(20px, #000);
                    line-height: 40px;
                }
            }
            .itemtitle {
                flex: 1;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .title {
                    .atitle {
                        @include sys-text-hide(1);
                        @include sys-font(48px, #000, bold);
                        line-height: 50px;
                        margin-bottom: 30px;
                    }
                    .btitle {
                        @include sys-text-hide(3);
                        @include sys-font(20px, #000);
                        line-height: 34px;
                        margin-bottom: 36px;
                    }
                    .txt {
                        @include sys-picbox(18px, 20px);
                        line-height: 30px;
                        .picbox {
                            margin-right: 20px;
                            flex: none;
                            margin-top: 4px;
                        }
                        .t1, .t2 {
                            display: flex;
                            margin-bottom: 10px;
                            letter-spacing: 1px;
                            span { 
                                @include sys-text-hide(1);
                                @include sys-font(20px, $acolor);
                            }
                        }
                    }
                }
                .btn {
                    @include sys-radius-btn(200px, 60px);
                    @include sys-font(20px, #fff, bold);
                    span {
                        @include sys-text-hide(1);
                    }
                }
            }
        }
        .itemlist {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: -40px;
            .list-title {
                @include sys-text-hide(1);
                @include sys-font(28px, #000, bold);
                width: 100%;
                line-height: 30px;
                margin-bottom: 30px;
                text-align: center;
            }
            .item {
                @include sys-boxsize(680px, 300px, 20px, #fff);
                box-shadow: 0px 0px 50px 0px rgba(63,101,224,0.05);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 40px;
                padding: 40px;
                box-sizing: border-box;
                .itemtitle {
                    line-height: 26px;
                    .atitle {
                        @include sys-text-hide(1);
                        @include sys-font(24px, #000, bold);
                        margin-bottom: 20px;
                    }
                    .btitle {
                        @include sys-text-hide(2);
                        @include sys-font(16px, #333);
                        margin-bottom: 20px;
                        letter-spacing: 1px;
                    }
                    .txt {
                        @include sys-picbox(14px, 16px);
                        .picbox {
                            margin-right: 10px;
                            flex: none;
                        }
                        .t1, .t2 {
                            display: none;
                            align-items: center;
                            margin-bottom: 4px;
                            letter-spacing: 1px;
                            &.show {
                                display: flex;
                            }
                            span {
                                @include sys-text-hide(1);
                                @include sys-font(16px, $acolor);
                            }
                        }
                    }
                }
                .more {
                    @include sys-picbox(14px, 10px);
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .picbox {
                        margin-left: 10px;
                        flex: none;
                    }
                    span {
                        @include sys-text-hide(1);
                        @include sys-font(16px, #000);
                    }
                }
            }
        }
    }
    .popup {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        &.active {
            opacity: 1;
            visibility: visible;
        }
        .shadow {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .8);
        }
        .detail-popup {
            @include sys-flex();
            @include sys-boxsize(1200px, 800px, 20px, #fff);
            padding: 0 60px 60px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .titlebox {
                @include sys-flex(space-between, row);
                width: 100%;
                height: 120px;
                margin-bottom: 40px;
                flex: none;
                position: relative;
                &::after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: #EEEEEE;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                .atitle {
                    @include sys-text-hide(1);
                    @include sys-font(36px, #000, bold);
                }
                .close {
                    @include sys-radius-btn(40px, 40px, #000);
                    @include sys-picbox(16px, 16px);
                    overflow: hidden;
                    transition: transform .3s;
                    &:hover {
                        transform: rotate(90deg);
                    }
                }
            }
            .conbox {
                flex: 1;
                width: 100%;
                @include sys-font(20px, #000);
                overflow-y: auto !important;
                &::-webkit-scrollbar {
                    width: 3px;
                }
                &::-webkit-scrollbar-thumb {
                    background: $acolor;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar-resizer {
                    background: $acolor;
                }
                &::-webkit-scrollbar-track-piece {
                    background: #fff;
                    border-radius: 2px;
                }
                p {
                    text-indent: 40px;
                }
            }
        }
    }
}
</style>